*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  width: 100vw;
  overflow: hidden;
  font-size: 16px;
  margin: 0;
  font-family: "Roboto", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 4rem;
}

h3 {
  font-size: 2.5rem;
}

h2.title {
  font-size: 2.5rem;
  font-weight: 300;
}

p.text {
  font-size: 1rem;
  font-weight: 400;
}

.in-grid {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wedge-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10vh 100vw 0 0;
  border-color: $white transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.wedge-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10vh 100vw;
  border-color: transparent transparent $white transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 5;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}
