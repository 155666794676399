.hero {
  &-gradient-background {
    &::before {
      z-index: -1;
      content: "";
      position: fixed;
      width: 400%;
      height: 400%;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom right,
        $purple 0%,
        $pink 33%,
        $purple 67%,
        $pink 100%
      );
      animation: move linear infinite 10s;
    }
    &::after {
      z-index: -1;
      background-image: url("../../assets/screen-bg.jpg");
      content: "";
      position: fixed;
      background-position: center;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      top: 0;
      left: 0;
    }
  }
  &-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: relative;
    background: transparent;
  }

  &-message {
    position: relative;
    color: $white;
    h1 {
      font-weight: 400;
    }
    h3 {
      font-weight: 300;
      overflow: hidden;
      span {
        display: inline-block;
        color: $yellow;
        font-weight: 700;
      }
    }
  }
}

.slideIn {
  animation: slideIn;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.slideOut {
  animation: slideOut;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.slideIn {
  animation: slideIn;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.slideOut {
  animation: slideOut;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
@keyframes move {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-66%, -66%, 0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideIn {
  0% {
    transform: translate3d(0, -100vw, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideOutRight {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100vw, 0, 0);
  }
}
@keyframes slideOut {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}

@media (min-width: 1024px) {
  .hero-message {
    & h1 {
      font-size: 7em;
    }
  }
}

@media (max-width: 450px) {
  .hero-message {
    & h1 {
      font-size: 3em;
    }
  }
  .slideIn {
    animation: slideInLeft;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }
  .slideOut {
    animation: slideOutRight;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }
}
