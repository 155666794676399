.overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
}

.modal {
  height: auto;
  & .inner {
    min-height: 40vh;
    max-height: 80vh;
  }
  & p a {
    color: $blue;
    &:hover {
      font-weight: 500;
      color: darken($blue, 10%);
    }
  }
  & ol {
    list-style: decimal;
    list-style-position: inside;
  }
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.close-icon {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.youtube-player {
  width: auto;
  height: auto;
  & iframe {
    height: inherit;
    width: inherit;
  }
}
@media (min-width: 710px) {
  .youtube-player {
    width: 560px;
    height: 315px;
    & iframe {
      height: 100%;
      width: 100%;
    }
  }

  .modal .inner {
    max-height: 90vh;
  }
}
