.card {
  position: relative;
  height: 250px;
  background-position: center;
  background-size: cover;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  & .info {
    display: none;
  }
  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transform: translate3d(2px, 2px, 0);
  }
  & img.foreground-image {
    transition: 0.2s;
    opacity: 1;
  }
}

@keyframes BlurFadeOut {
  from {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    filter: blur(0);
  }
  to {
    opacity: 0;
    transform: scale3d(1.5, 1, 1);
    filter: blur(4px);
  }
}
@keyframes InfoFadeIn {
  from {
    opacity: 0;
    filter: blur(4px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
}

@media (min-width: 1025px) {
  .card {
    &:hover img.foreground-image {
      animation: BlurFadeOut;
      animation-duration: 200ms;
      animation-fill-mode: both;
    }
    & .info {
      display: flex;
      position: absolute;
      opacity: 0;
    }
    &:hover .info {
      animation: InfoFadeIn;
      animation-duration: 0.3s;
      animation-fill-mode: both;
      animation-delay: 0.2s;
      background: rgba(0, 0, 0, 0.7);
      color: white;
      content: "";
      text-align: center;
      font-size: 2em;
      height: 100%;
      width: 100%;
    }
  }
}
